<template>
  <CCard>
    <CCardBody>
      <h3 v-text="method"></h3>
      <CAlert :show.sync="dismissCountDown" :color="alertType" fade>
        {{ message }}
      </CAlert>
      <CRow>
        <CCol>
          <CInput
            label="Name"
            type="text"
            placeholder="Name"
            v-model="experienceStatus.name"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="9"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton
            color="secondary"
            class="btn-lg"
            @click="goBack"
            style="margin-right: 15px"
            >Back</CButton
          >

          <CButton color="primary" @click="update()" class="btn-lg"
            >Save</CButton
          >
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
export default {
  name: "EditExperienceStatus",
  data: () => {
    return {
      alertType: "danger",
      method: "Create New Experience Status",
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      experienceStatus: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
      },
      message: null,
      dismissSecs: 7,
      dismissCountDown: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    update() {
      let self = this;
      axios
        .post(this.$apiAdress + "/v1/ExperienceStatus", self.experienceStatus)
        .then((response) => {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully updated experience status.";
          self.experienceStatus.id = response.data;
          self.showAlert();
        })
        .catch(function (error) {
          if (error.response.data.message == "The given data was invalid.") {
            self.message = "";
            for (let key in error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty(key)) {
                self.message += error.response.data.errors[key][0] + "  ";
              }
            }
            self.showAlert();
          } else {
            console.log(error);
          }
        });
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    get(id) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ExperienceStatus/" + id)
        .then(function (response) {
          self.experienceStatus = response.data;
        })
        .catch(function (error) {
          this.message = error;
          console.log(error);
        });
    },
  },
  mounted: function () {
    let self = this;
    // Edit OR Create
    if (self.$route.params.id != this.emptyGuid) {
      self.method = "Edit Experience Status";
      self.get(self.$route.params.id);
    }
  },
};
</script>
